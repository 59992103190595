[class^="text-"][class$="-semibold"] {
    font-weight: 600;
}

[class^="text-"][class$="-medium"] {
    font-weight: 500;
}

[class^="text-"][class$="-regular"] {
    font-weight: 400;
}

[class^="text-xl-"] {
    font-size: 20px;
    line-height: 30px;
}

[class^="text-lg-"] {
    font-size: 18px;
    line-height: 28px;
}

[class^="text-md-"] {
    font-size: 16px;
    line-height: 24px;
}

[class^="text-sm-"] {
    font-size: 14px;
    line-height: 20px;
}

[class^="text-xs-"] {
    font-size: 12px;
    line-height: 18px;
}